<template>
    <section class="text-block text-block--right-text">
        <b-container fluid="xl">        
            <b-row>
                <b-col cols="12" md="6"                     
                    class="text-block__img-wrap"
                    v-inview="'fade-from-left'"                    
                >
                    <div class="text-block__img" v-if="imagesrc">                        
                        <img 
                            :src="imagesrc" 
                            class="img-fluid"
                        />
                    </div>                    
                </b-col>
                <b-col cols="12" md="6" class="text-block__content-wrap" v-inview="'fade-from-right'">
                    <div class="text-block__content" >
                        <div class="text" v-html="text"></div>                        
                    </div>                            
                </b-col>
            </b-row>            
        </b-container>
    </section>
</template>

<script>
export default {
    props:['text', 'imagesrc']
}
</script>