<template>
    <div class="text-block text-block--right-text"  v-if="currentProduct" >        
        <b-container fluid>        
            <b-row>
                <b-col cols="12" md="6" xl="5"
                    class="text-block__img-wrap" 
                    v-inview="'fade-from-left'"                                       
                >
                    <img v-if="this.currentProduct.background_image" :src="moduleBgr" class="drawing-img img-fluid"  />
                    <div class="text-block__img" v-if="currentProduct.main_image" >                          
                        <img v-if="currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj" src="@/assets/images/brand/ovatos-duhaj-logo.svg" class="od-logo" alt="Óvatos Duhaj"/>
                        <img v-if="currentProduct.main_image"
                            :src="src_url + itemSrc(currentProduct.main_image, 'w600h1280')" 
                            :srcset="itemSrcset(currentProduct.main_image)"
                            class="img-fluid main-img"
                            sizes="(max-width: 767px) 100vw, 50vw"
                        />
                    </div>                    
                </b-col>
                <b-col cols="12" md="6" offset-xl="1" class="text-block__content-wrap" v-inview="'fade-from-right'">                    
                    <div class="text-block__content" >                       
                        <h1 class="h2">
                            <span v-if="currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj">
                                Soproni Óvatos Duhaj <br/>
                            </span>
                            {{currentProduct.name}}
                        </h1>                        
                        <div class="text" v-if="currentProduct.description" v-html="currentProduct.description"></div> 
                        <div class="tag-wrap d-flex font-solido flex-wrap" v-if="currentProduct.product_tag">
                            <div class="tag d-inline-flex align-items-center flex-nowrap" v-for="tag in currentProduct.product_tag" :key="tag.id">
                                <div class="img-wrap" v-if="tag.icon_image">
                                    <img :src="src_url + tag.icon_image.path + tag.icon_image.file_name"  />
                                </div>                                
                                <div>
                                    <div>{{tag.text_light}}</div>
                                    <div>{{tag.text_bold}}</div>
                                </div>                                
                            </div>
                        </div>                       
                        <div class="btn-wrap">                           
                            <button      
                                v-if="nutritionData"                          
                                v-b-modal="'nutrient-table_' + currentProduct.slug + ''"
                                :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj ? 'btn btn--gold-border' : 'btn btn--green-border']"
                            >
                                <span>tápanyag táblázat</span>
                            </button>
                            <a 
                                v-if="currentProduct.shop_link"
                                :href="currentProduct.shop_link"
                                target="_blank"                                
                                :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj ? 'btn btn--gold btn--shop' : 'btn btn--green btn--shop']"
                                @click="setGTMCart(currentProduct.name)"
                            >
                                <span>vásárlás</span>
                            </a>
                        </div>                                               
                    </div>                            
                </b-col>
            </b-row>            
        </b-container>    
        <!-- TAPANYAGTABLAZAT MODAL -->
        <b-modal v-if="nutritionTable"
            :id="'nutrient-table_' + currentProduct.slug" 
            size="lg" 
            hide-header-close hide-header
            hide-footer
            centered 
        >
            <div class="nutrition-modal">
                <div v-if="nutritionData" v-html="nutritionData.description"></div>
            </div>
        </b-modal>    
    </div>
</template>

<script>
export default {
    props:['currentProduct', 'currentProdFamily', 'nutritionTable'],
    data(){
        return{            
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,
            // nutritionData: null,
        }
    },
    computed:{
        moduleBgr(){
            if (this.currentProduct.background_image){
                return this.src_url + this.currentProduct.background_image.path + this.currentProduct.background_image.file_name
            } else {
                return ""
            }            
        },
        nutritionData(){
            let _this = this
            let nutitionTableIdentifier = 'tapanyag-tablazat-' + this.currentProduct.slug + ''          

            if (_this.nutritionTable && _this.nutritionTable.length > 0) {            
                return _this.nutritionTable.find(item => item.identifier == nutitionTableIdentifier)
            } else {
                return null
            }
        }
    }, 
    methods:{
        setGTMCart(product){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'termekek_reszletek_oldal',
                'action': 'vasarlas_gombra_kattintas',                
                'page_type': 'termekek_reszletek',
                'button': 'vasarlas',            
                'product': product,
                'action_type': 'gomb_kattintas'
            })
        }
    }   
}
</script>