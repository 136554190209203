<template>
    <div class="product-details page" v-if="currentProdFamily != null" :class="currentProdFamily.css_class">
        <!-- MAIN TEXT BLOCK -->                
        <ProductMainTextBlock 
            :currentProduct="currentProduct" 
            :currentProdFamily="currentProdFamily" 
            :nutritionTable="currentPageModulesTexts" 
        />        
        
        <!-- VIDEO -->        
    
        <section class="video-wrap" v-if="currentProduct.video && currentProduct.poster">        
            <Video 
                :video="src_url + currentProduct.video.path + currentProduct.video.file_name" 
                :staticVideoName="''" 
                :staticVideo="false" 
                :needScrollBtn="false" 
                :poster="src_url + currentProduct.poster.path + currentProduct.poster.file_name"
                v-inview="'fade-up'"
            />            
        </section>
        <!-- BOTTOM TEXT BLOCK -->
        <section class="product-details__bottom-text" v-if="currentProduct.bottom_description" >
            <TextBlockBottom 
                :text="currentProduct.bottom_description" 
                :imagesrc="textBottomImg" 
            />
        </section>
        <!-- RECIPIES -->
        <section class="product-details__offer-recipies recipe-offer--details-page" v-if="currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj && recipes.length>0" v-inview="'fade-up'">
            <b-container>
                <b-row >
                    <b-col cols="12">
                        <div class="title">
                            <h2 class="text--gold">Ajánlott receptek</h2>
                            <img src="@/assets/images/brand/ovatos-duhaj-logo.svg" class="od-logo" alt="Óvatos Duhaj"/>
                        </div>            
                    </b-col>
                </b-row>
                <b-row class="recipe-cards-wrap row" v-if="recipes.length>0">
                    <b-col cols="12" md="6" v-for="recipe in recipes" :key="recipe.id">
                        <div class="recipe-item-wrap" >
                            <RecipeCard :recipe="recipe" />
                        </div>                        
                    </b-col>                
                </b-row>      
                <div class="d-flex justify-content-center btn-wrap">
                    <router-link class="btn btn--gold-border btn--with-arrow" :to="{name:'RecipeAll'}" >
                        <span>tovább a receptekhez</span>
                    </router-link>
                </div>
            </b-container>            
        </section>

        <!-- OTHERS IN THIS FAMILY -->
        <Brothers :brothers="brothers" v-if="brothers.length > 0" :currentProdFamily="currentProdFamily" v-inview="'fade-up'"/>        
        
        <!-- OTHER PROD FAMILIES -->
        <section class="other-pdf-products" v-inview="'fade-up'">
            <h3>További termékcsaládok</h3>
        </section>        
        <ProdFamilySlider  :needApiCall="false" :prodFamiliesAll="prodFamilies" v-if="prodFamilies.length > 0" />
        <PageLoading /> 
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'
import ProductMainTextBlock from '@/components/pages/productDetails/ProductMainTextBlock.vue'
import Brothers from '@/components/pages/productDetails/Brothers.vue'
import ProdFamilySlider from '@/components/pages/productFamily/ProdFamilySlider.vue'
import RecipeCard from '@/components/pages/recipe/RecipeItem.vue'
import TextBlockBottom from '@/components/pages/productDetails/TextBlockBottom.vue'
import Video from '@/components/layout/Video.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
    name: 'ProductDetails',
    mixins: [apiH,viewMI],
    components:{
        ProductMainTextBlock,
        Brothers,
        ProdFamilySlider,
        RecipeCard,
        TextBlockBottom,
        Video,
        PageLoading                
    },
    data(){
        return{
            currentProduct: null,
            prodFamilies:[],
            currentProdFamily:null,            
            brothers:[],
            recipes: [],
            modulesIdentifiers: []            
        }
    },
    computed:{
        textBottomImg(){
            return this.currentProduct.bottom_image ? this.src_url + this.currentProduct.bottom_image.path + this.currentProduct.bottom_image.file_name : null
        }
    },
    watch:{
        $route(){     
            //set default state
            this.currentProduct = null,
            this.prodFamilies = [],
            this.currentProdFamily = null,                                    
            
            //call the data get fgv again        
            this.getProdFamilies()            
        }
    },
    methods:{            
        getProdFamilies(){
            let _this = this

            _this.get('product_category',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],                                                
                        ],
                        relations_params:{
                            product:{
                                // select: ['name', 'shop_link', 'slug', 'card_image'],
                                where: [['where', 'status', '1']],
                                order_by:[['sort_order', 'asc'], ['name', 'asc']],
                                relations:1,                                                           
                            }
                        }

                    }
                },
                function(resp){                                        
                    _this.prodFamilies = resp.data.product_category
                    if (_this.$route.params.product_family_slug) {
                        //if the route has product_family_slug
                        _this.getCurrentProdFamily()
                    }                                         
                }, function(err){
                    console.log(err);
                    _this.prodFamilies = []
                }
            )
        },
        getCurrentProdFamily()  {
            let _this = this
            let currentSlug = this.$route.params.product_family_slug 

            this.brothers = [] 

            this.prodFamilies.forEach( element => {
                if (element.slug == currentSlug ) {
                    // _this.currentProdFamily = element                    
                    var currentPFItemIndex =  _this.prodFamilies.indexOf(element)
                    //get the current prod family
                    _this.currentProdFamily = element

                    if (currentPFItemIndex > -1) {
                        _this.prodFamilies.splice(currentPFItemIndex, 1);
                    }
                }
            })

            this.getProductsArrays()

        },
        getProductsArrays(){
            let _this = this
            let currentSlug = this.$route.params.product_slug         
                        
            
            this.currentProdFamily.product.forEach( element => {
                if (element.slug == currentSlug ) {                    
                    //get the current product
                    _this.currentProduct = element                    
                    _this.getRecipes() 
                    _this.setMetas()

                    //get the nutrition table
                    let modulIdText = 'tapanyag-tablazat-' + _this.currentProduct.slug + ''
                    // console.log('modulIdText', modulIdText)
                    _this.modulesIdentifiers.push(modulIdText)
                    _this.getModuls(_this.modulesIdentifiers)  
                } else {
                    _this.brothers.push(element)
                }
                
            })

            //404 page
            if (!this.currentProduct){
                _this.$router.push({name: '404-page'})
            }

        },
        getRecipes(){
          let _this = this

          this.recipes = []

            _this.get('recipe_category',
                    {
                        params: {                          
                            relations:1,                                                                   
                            where: [
                                ['where','status', '1'],    
                                ['where', 'product_id', _this.currentProduct.id],                                
                            ],                                                        
                            relations_params:{                                   
                                recipe:{ 
                                    relations:1,
                                    limit:2,
                                    order_by:[
                                        ['created_at','desc'],
                                        ['sort_order','asc']                
                                    ],
                                    where: [['where','image!=', '0']]
                                },                        
                                product:{
                                    hidden:1
                                }            
                            }                                                         
                        }
                    },
                    function(resp){                                        
                        if (resp.data.recipe_category[0].recipe){
                            _this.recipes = resp.data.recipe_category[0].recipe
                        } else {
                            _this.recipes = []
                        }

                    }, function(

                    ){
                        // console.log(err);
                        _this.recipes = []
                    }
                )
        },
        setMetas() {
            this.setMetaTags({
                title: this.currentProduct.name, 
                desc: this.currentProduct.meta_description, 
                keywords: this.currentProduct.meta_keywords, 
                ogType: 'website', 
                ogTitle: this.currentProduct.name,                         
                ogImage: this.currentProduct.meta_image, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc: this.currentProduct.meta_description
            })
        }
    },
    created(){
        // this.getCurrentProduct(),        
        this.getProdFamilies()  
         
        
    }
}
</script>